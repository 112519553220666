import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Menu, PhoneCall, X } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import logo from "./logo.png"
// import { ReactComponent as Whatsapp } from '../../assets/whatsapp.svg';
import "./navbar.css";
export default function Navbar() {
  let location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [scrollPosition, setPosition] = useState(0);
  const [route, setRoute] = useState('/');
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, [scrollPosition]);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setRoute(location.pathname);
  }, [location])

  useEffect(() => {


    if (showMenu) {
      let app = document.getElementById('empty');
      app.addEventListener('click', function () {
        setShowMenu(false)
      })
    }

  }, [showMenu])

  return (
    <>
      <nav
        className={`${scrollPosition > 50 ? "solid" : "transparent"}`}
      >
        <div className="logo">
          <Link title="MVE Logo" to="/"><img src={logo} alt="" /></Link>
          <div className="logo-head"><Link to="/">MAA VAISHNAVI ENTERTAINMENTS</Link></div>
        </div>
        <div className="links">
          <Link to="/" className={route === "/" ? "Active" : ""}  >Home</Link>
          <Link to="/about" className={route === "/about" ? "Active" : ""}>About Us</Link>
          <Link to="/team" className={route === "/team" ? "Active" : ""}>Team</Link>
          <Link to="/services" className={route === "/services" ? "Active" : ""}>Services</Link>
          <Link to="/gallery" className={route === "/gallery" ? "Active" : ""}>Gallery</Link>
          <Link to="/portfolio" className={route === "/portfolio" ? "Active" : ""}>Portfolio</Link>
          <Link to="/career" className={route === "/career" ? "Active" : ""}>Career</Link>
          <Link to="/contact" className={route === "/contact" ? "Active" : ""}>Contact Us</Link>
          <div className="col">
            <a className="btn-2" href="tel:+919407271262"><PhoneCall /><span>+919407271262</span></a>
          </div>
        </div>

        <div className="info">
          <div className="col">
            <a className="btn-2" href="tel:+919407271262"><PhoneCall /><span>+919407271262</span></a>

          </div>
          {/* <div className="col">
            <a className="btn-1" href="https://api.whatsapp.com/send?phone=917880008868" rel="noreferrer" target="_blank"><Whatsapp /> <span> Consult Online</span></a>
          </div> */}
        </div>
        <button
          onClick={() => {
            setShowMenu(true);
          }}
          className="menu"
        >
          <Menu />
        </button>


      </nav>
      {showMenu && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, x: "+10vw" }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0, x: "+10vw" }}
            className="sideBar"
          >
            <div className="empty" id='empty'>

            </div>
            <div className="bar">
              <div className="top">
                <button
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  className="menu"
                >
                  <X />
                </button>
              </div>
              <div className="links" onClick={() => setShowMenu(false)}>
                <Link to="/" className={route === "/" ? "Active" : ""}>Home</Link>
                <Link to="/about" className={route === "/about" ? "Active" : ""}>About Us</Link>
                <Link to="/team" className={route === "/team" ? "Active" : ""}>Team</Link>
                <Link to="/services" className={route === "/services" ? "Active" : ""}>Services</Link>
                <Link to="/gallery" className={route === "/gallery" ? "Active" : ""}>Gallery</Link>
                <Link to="/portfolio" className={route === "portfolio" ? "Active" : ""}>Portfolio</Link>
                <Link to="/career" className={route === "/career" ? "Active" : ""}>Career</Link>

                <Link to="/contact" className={route === "/contact" ? "Active" : ""}>Contact Us</Link>
                <div className="row">
                  <PhoneCall /> <a href="tel:+919407271262">9407271262</a>
                </div>
                <div className="info">
                  <div className="col">
                    <h4>Call Us At</h4>

                  </div>
                  <div className="col">
                    <h4>For Online Consultation</h4>
                    {/* <div className="row">
                      <Whatsapp /><a href="https://api.whatsapp.com/send?phone=917880008868" rel="noreferrer" target="_blank">Consult Online</a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
}
